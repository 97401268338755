import { RefObject, useCallback, useEffect } from 'react';
import { useEventListener } from './useEventListener';

type UseClickOutsideOptions = {
  skip?: boolean;
};

export function useClickOutside<T extends HTMLElement>(
  refs: RefObject<T> | RefObject<T>[],
  callback: (e: Event) => void,
  deps: any[] = [], // Optional dependencies array
  options?: UseClickOutsideOptions
) {
  // Normalize refs to an array
  const refsArray = Array.isArray(refs) ? refs : [refs];

  const handleClick = useCallback(
    (e: Event) => {
      if (options?.skip) return;

      // Check if the click was outside all of the refs
      const clickedOutside = refsArray.every(
        (ref) => ref.current && !ref.current.contains(e.target as Node)
      );

      if (clickedOutside) {
        callback(e);
      }
    },
    [callback, options?.skip, ...refsArray.map((ref) => ref.current), ...deps]
  );

  useEventListener('mousedown', handleClick);
}

export default useClickOutside;
